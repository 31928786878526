/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

// GENERAL
// ---------------

$color1:              #dd1a32;
$color2:              #ad0015;
$color3:              #3d5567;
$color4:              #314452;
$color5:              #ebeef0;

// TYPOGRAPHY
// ---------------

$textColor:           #231f20;

$baseFontSize:        16px;
$em-base:             $baseFontSize;
$baseLineHeight:      1.612;
$baseTypeSpacing:     round($baseFontSize * $baseLineHeight);
$baseFontFamily:      "Raleway", Helvetica, Arial, sans-serif;
$secondaryFontFamily: $baseFontFamily;

$headingsFontFamily:  $secondaryFontFamily;
$headingsColor:       $textColor;

$hrColor:             #DDD;
