/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

@import url("//fonts.googleapis.com/css?family=Raleway:300,300i,400,400i,700,700i,800,800i&display=swap");
@import "variables";

body.login {
	background: $color5;
	font-family: $baseFontFamily;
	h1 {
		a {
			width: 220px;
			height: 86px;
			margin: 0 auto 40px;
			background: url(../img/logo.png) no-repeat center center / contain;
		}
	}
	form {
		box-shadow: none;
	}
}

#login {
	background: none;
	box-shadow: none;
}

#loginform {
	// box-shadow: 0 0 12px rgba(#000, 0.15);
	border-radius: 20px 0 20px 0;
}

.login #backtoblog,
.login #nav {
	a {
		color: $color1;
		&:hover {
			color: $color2;
			text-decoration: underline;
		}
	}
}

.wp-core-ui {
	.button-primary {
		background: $color1;
		color: #FFF;
		border: none;
		border-radius: 5px 0 5px 0;;
		box-shadow: none;
		text-shadow: none;
		text-transform: uppercase;
		font-weight: 700;
		transition: all 0.2s ease-in-out;
		&:hover,
		&:active,
		&:focus {
			background: $color2 !important;
			outline: 0;
			box-shadow: none !important;
		}
	}
}
